const APP_FORM_GET_STARTED = 1
const APP_FORM_PARENT_DASHBOARD = 2

const APP_FORM_APPOINTMENT_PAGE_LIMBO = 1
const APP_FORM_APPOINTMENT_PAGE_FAMILY = 9

const APP_FORM_CHILD = 1
const APP_FORM_ADULT = 2
const APP_FORM_SIBLING = 3
const APP_FORM_RETURN_CHILD = 4
const APP_FORM_RETURN_ADULT = 5
const APP_FORM_RETURN_SIBLING = 6

const APPLICATION_FORM_DRAFT = 1
const APPLICATION_FORM_SUBMITTED = 2
const APPLICATION_FORM_ARCHIVED = 3
const APPLICATION_FORM_ASSIGNED = 4
const APPLICATION_FORM_INCOMPLETE = 5

const APPLICATION_FORM_DRAFT_SUBMITTED_INCOMPLETE = 100

const APPLICATION_FORM_CLIENT_PAGES = [1, 15]
const APPLICATION_FORM_MOTHER_PAGE = 2
const APPLICATION_FORM_FATHER_PAGE = 3
const APPLICATION_FORM_GUARDIAN_OTHER_PAGE = 4
const APPLICATION_FORM_REST_CLIENT_INFO_PAGES = [5, 17]
const APPLICATION_FORM_MEDICAL_HISTORY_PAGES = [6, 18]
const APPLICATION_FORM_HEALTH_PAGES = [7, 19]
const APPLICATION_FORM_BEHAVIOR_PAGES = [8, 20]
const APPLICATION_FORM_SKILLS_PAGES = [9, 21]
const APPLICATION_FORM_DEVELOPMENTAL_HISTORY_PAGES = [10, 22]
const APPLICATION_FORM_EDUCATIONAL_HISTORY_PAGES = [11, 23]
const APPLICATION_FORM_GOALS_PAGES = [12, 24]
const APPLICATION_FORM_AGREEMENT_PAGES = [13, 25]
const APPLICATION_FORM_PAYMENT_AUTHORIZATION_PAGES = [14, 26]
const APPLICATION_FORM_SPOUSE_PARENT_GUARDIAN_PAGE = 16

const APPLICATION_FORM_OTHER_INFO_TYPES = [3, 4]

const APPLICATION_FORM_CATEGORY_CHILD = 1
const APPLICATION_FORM_CATEGORY_ADULT = 2

const APPLICATION_FORM_CLIEN_LIVE_WITH_OTHER_STATUSES = [5, 10]
const APPLICATION_FORM_COMPLETED_BY_OTHER = 3

const APPLICATION_FORM_PHYSICAL_MOTOR_SKILL_OTHER = 12
const APPLICATION_FORM_HAND_PREFERENCE_ABILITY_OTHER = 6

const INCOME_GT_100 = 1
const INCOME_LT_100_GT_50 = 2
const INCOME_LT_50 = 3

const AUSTRALIA = 3
const EASTERN_EUROPE = 4
const UNITED_STATES_UTAH_FITS_ALL_SCHOLARSHIP = 16

const APP_FORM_MEMBERSHIP_PAGES = [
  2, 3, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
]

const APPLICATION_FORM_SPECIALIST_COUNSELOR = 17
const APPLICATION_FORM_SPECIALIST_OTHER = 18

const APPLICATION_FORM_TIME_PERIOD_EXCESSIVE = 1
const APPLICATION_FORM_TIME_PERIOD_DAILY = 2
const APPLICATION_FORM_TIME_PERIOD_WEEKLY = 3
const APPLICATION_FORM_TIME_PERIOD_RARELY = 4
const APPLICATION_FORM_TIME_PERIOD_NEVER = 5

const APP_FORM_ABILITY_WRITING = 1
const APP_FORM_ABILITY_EATING = 2
const APP_FORM_ABILITY_THROWING = 3
const APP_FORM_ABILITY_BRUSHING_TEETH = 4
const APP_FORM_ABILITY_COMBING_HAIR = 5
const APP_FORM_ABILITY_OTHER = 6

export {
  APP_FORM_GET_STARTED,
  APP_FORM_PARENT_DASHBOARD,
  APP_FORM_CHILD,
  APP_FORM_ADULT,
  APP_FORM_SIBLING,
  APP_FORM_RETURN_CHILD,
  APP_FORM_RETURN_ADULT,
  APP_FORM_RETURN_SIBLING,
  APPLICATION_FORM_DRAFT,
  APPLICATION_FORM_SUBMITTED,
  APPLICATION_FORM_ARCHIVED,
  APPLICATION_FORM_ASSIGNED,
  APPLICATION_FORM_INCOMPLETE,
  APPLICATION_FORM_DRAFT_SUBMITTED_INCOMPLETE,
  APPLICATION_FORM_CLIENT_PAGES,
  APPLICATION_FORM_MOTHER_PAGE,
  APPLICATION_FORM_FATHER_PAGE,
  APPLICATION_FORM_GUARDIAN_OTHER_PAGE,
  APPLICATION_FORM_REST_CLIENT_INFO_PAGES,
  APPLICATION_FORM_MEDICAL_HISTORY_PAGES,
  APPLICATION_FORM_HEALTH_PAGES,
  APPLICATION_FORM_BEHAVIOR_PAGES,
  APPLICATION_FORM_SKILLS_PAGES,
  APPLICATION_FORM_DEVELOPMENTAL_HISTORY_PAGES,
  APPLICATION_FORM_EDUCATIONAL_HISTORY_PAGES,
  APPLICATION_FORM_GOALS_PAGES,
  APPLICATION_FORM_AGREEMENT_PAGES,
  APPLICATION_FORM_PAYMENT_AUTHORIZATION_PAGES,
  APPLICATION_FORM_SPOUSE_PARENT_GUARDIAN_PAGE,
  APPLICATION_FORM_OTHER_INFO_TYPES,
  APPLICATION_FORM_CATEGORY_CHILD,
  APPLICATION_FORM_CATEGORY_ADULT,
  APPLICATION_FORM_CLIEN_LIVE_WITH_OTHER_STATUSES,
  APPLICATION_FORM_COMPLETED_BY_OTHER,
  APPLICATION_FORM_PHYSICAL_MOTOR_SKILL_OTHER,
  APPLICATION_FORM_HAND_PREFERENCE_ABILITY_OTHER,
  INCOME_GT_100,
  INCOME_LT_100_GT_50,
  INCOME_LT_50,
  AUSTRALIA,
  EASTERN_EUROPE,
  UNITED_STATES_UTAH_FITS_ALL_SCHOLARSHIP,
  APP_FORM_MEMBERSHIP_PAGES,
  APPLICATION_FORM_SPECIALIST_COUNSELOR,
  APPLICATION_FORM_SPECIALIST_OTHER,
  APPLICATION_FORM_TIME_PERIOD_EXCESSIVE,
  APPLICATION_FORM_TIME_PERIOD_DAILY,
  APPLICATION_FORM_TIME_PERIOD_WEEKLY,
  APPLICATION_FORM_TIME_PERIOD_RARELY,
  APPLICATION_FORM_TIME_PERIOD_NEVER,
  APP_FORM_ABILITY_WRITING,
  APP_FORM_ABILITY_EATING,
  APP_FORM_ABILITY_THROWING,
  APP_FORM_ABILITY_BRUSHING_TEETH,
  APP_FORM_ABILITY_COMBING_HAIR,
  APP_FORM_ABILITY_OTHER,
  APP_FORM_APPOINTMENT_PAGE_LIMBO,
  APP_FORM_APPOINTMENT_PAGE_FAMILY,
}
