import familyApplicationFormRoutes from "@/router/modules/application-form.js"

const profileRoutes = [
  {
    path: "/profile",
    name: "ProfileLayout",
    component: () =>
      import(
        /* webpackChunkName: "profile-view" */ "@/views/profile/Layout.vue"
      ),
    children: [
      {
        path: "view",
        name: "ProfileView",
        meta: { title: "My Profile" },
        component: () =>
          import(
            /* webpackChunkName: "profile-view" */ "@/views/profile/View.vue"
          ),
      },
      {
        path: "edit",
        name: "ProfileEdit",
        meta: { title: "Edit My Profile" },
        component: () =>
          import(
            /* webpackChunkName: "profile-edit" */ "@/views/profile/Edit.vue"
          ),
      },
      {
        path: "change-password",
        name: "ProfileChangePassword",
        meta: { title: "Change Password" },
        component: () =>
          import(
            /* webpackChunkName: "profile-change-password" */ "@/views/profile/ChangePassword.vue"
          ),
      },
      {
        path: "billing",
        name: "ProfileBilling",
        meta: { title: "Billing Information" },
        component: () =>
          import(
            /* webpackChunkName: "profile-billing" */ "@/views/profile/Billing.vue"
          ),
      },
      {
        path: "send-referral-code",
        name: "SendReferralCode",
        meta: { title: "Send Referral Code" },
        component: () =>
          import(
            /* webpackChunkName: "profile-billing" */ "@/views/profile/SendReferralCode.vue"
          ),
      },
      ...familyApplicationFormRoutes,
    ],
  },
]

export default profileRoutes
