import AuthService from "@/services/AuthService"
import { StaffLoginAsFamily } from "@/graphql/mutations/auth/staffLoginAsFamily.gql"
import { FamilyLogin } from "@/graphql/mutations/auth/familyLogin.gql"
import { FamilySignUp } from "@/graphql/mutations/auth/familySignUp.gql"
import { FamilyResetPassword } from "@/graphql/mutations/auth/familyResetPassword.gql"

import { apolloClient } from "@/apollo"
import { SUCCESS, NO_PROFILE_IMAGE } from "@/constants"

export const namespaced = true

let authService = new AuthService()

export const state = {
  user: authService.getUser(),
  loginAs: authService.getLoginAs(),
}

export const mutations = {
  SET_LOGIN_AS(state, value) {
    state.loginAs = value
  },
  SET_USER(state, user) {
    state.user = user
  },
}

export const actions = {
  update({ commit }, user) {
    if (user.user_id === authService.id()) {
      commit("SET_USER", user)
      authService.setUser(user)
    }
  },
  resetPassword({ commit, dispatch }, model) {
    return apolloClient
      .mutate({
        mutation: FamilyResetPassword,
        variables: {
          model,
        },
      })
      .then((response) => {
        let data = response.data.familyResetPassword
        commit("SET_USER", data.family)
        authService.setData(data)

        let notification = {
          type: SUCCESS,
          message: "You are successfully signed in!",
        }

        dispatch("notification/add", notification, { root: true })
      })
  },
  login({ commit, dispatch }, credentials) {
    return apolloClient
      .mutate({
        mutation: FamilyLogin,
        variables: {
          login: credentials,
        },
      })
      .then((response) => {
        let data = response.data.familyLogin
        commit("SET_USER", data.family)
        authService.setData(data)

        let notification = {
          type: SUCCESS,
          message: "You are successfully signed in!",
        }

        dispatch("notification/add", notification, { root: true })

        return data
      })
  },
  loginAsFamily({ commit, dispatch }, credentials) {
    return apolloClient
      .mutate({
        mutation: StaffLoginAsFamily,
        variables: {
          login: credentials,
        },
      })
      .then((response) => {
        let data = response.data.staffLoginAsFamily
        commit("SET_USER", data.family)
        commit("SET_LOGIN_AS", true)
        authService.setLoginAs(true)
        authService.setData(data)

        let notification = {
          type: SUCCESS,
          message: "You are successfully signed in!",
        }

        dispatch("notification/add", notification, { root: true })

        return data
      })
  },
  signUp({ commit, dispatch }, variables) {
    return apolloClient
      .mutate({
        mutation: FamilySignUp,
        variables,
      })
      .then((response) => {
        let tokenInfo = response.data.familySignUp
        commit("SET_USER", tokenInfo.family)
        authService.setData(tokenInfo)

        let notification = {
          type: SUCCESS,
          message: "You are successfully signed up!",
        }

        dispatch("notification/add", notification, { root: true })

        let credentials = {
          username: tokenInfo.family.user.username,
          password: variables.user.password,
        }

        dispatch("limboCredentials/show", credentials, { root: true })

        return tokenInfo
      })
  },
  backToAdmin({ commit }) {
    authService.logout().then(() => {
      commit("SET_USER", null)
      commit("SET_LOGIN_AS", false)
      window.location.href = process.env.VUE_APP_ADMIN_URL
    })
  },
  logout({ commit, dispatch }) {
    authService.logout().then(() => {
      commit("SET_USER", null)
      commit("SET_LOGIN_AS", false)

      let notification = {
        type: SUCCESS,
        message: "You are successfully signed out!",
      }

      dispatch("notification/add", notification, { root: true })
    })
  },
}

export const getters = {
  canSendReferralCode(state) {
    return state.user.badge_id !== null
  },
  pageSettings(state) {
    return state.user ? state.user.pageSettings : null
  },
  photo: (state) =>
    state.user && state.user.photo ? state.user.photo : NO_PROFILE_IMAGE,
}
