const guestRoutes = [
  {
    path: "/login",
    name: "Login",
    component: () =>
      import(/* webpackChunkName: "login" */ "@/views/Login.vue"),
  },
  {
    path: "/login-as-family",
    name: "Login As Family",
    props: (route) => ({
      family_username: route.query.family_username,
    }),
    component: () =>
      import(/* webpackChunkName: "login" */ "@/views/LoginAsFamily.vue"),
  },
  {
    path: "/sign-up",
    name: "SignUp",
    props: (route) => ({
      referral_code: route.query.referral_code || null,
    }),
    component: () =>
      import(/* webpackChunkName: "sign-up" */ "@/views/SignUp.vue"),
  },
  {
    path: "/reset-password",
    name: "SendResetPasswordLink",
    meta: { title: "Send Reset Password Link" },
    component: () =>
      import(
        /* webpackChunkName: "reset-password" */ "@/views/SendResetPasswordLink.vue"
      ),
  },
  {
    path: "/reset-password/:token",
    name: "ResetPassword",
    meta: { title: "Reset Password" },
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "reset-password" */ "@/views/ResetPassword.vue"
      ),
  },
]

export default guestRoutes
